/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react'

function Vcard() {
  return (
    <>
      <iframe src="/vcard/gertitefa/index.html" style={{ width: '100%', height: '100vh' }} />
    </>
  )
}

export default Vcard
